import React from 'react';

class Home extends React.Component {

    render(){
        return (
            <div className="center-panel">
                <h1>
                    {`Hi, my name is Kiri Um. 
                    I am a full-stack software engineer.    `}
                </h1>
            </div>
        )
    }
}
export default Home